<template>
    <div>
        <highcharts :options="chartOptions"></highcharts>
        <!--<div class="stat-label" v-if="hasNegativeNumbers">This month includes chargebacks from the following carriers:
            <span v-for="(d,k) in data" :key="k">
                <span v-if="d < 0">{{k}} ({{formatCurrency(d)}})</span>
            </span>
        </div>//-->
    </div>
</template>
<script>
import { Chart } from 'highcharts-vue'
import colors from '@/components/charts/ChartColors';
//import mockdata from '@/store/sample_data/MockDataHighcharts'
import theme from '@/components/charts/Theme'
import dataTransformer from '@/store/dataTransformer'

export default {
    name: "QScoreCardDonutChart",
    props: ['data', 'title', 'subtitle', 'name', 'format', "colors"], // data passed to component from parent
    data: function() { // data internal to component (not available outside)
        var g = this;
        var data = dataTransformer.pieChart(this.data);
        return {
            chartOptions: {
                colors: this.colors,
                chart: {
                    type: 'pie',
                    height: 400
                },
                title: {
                    text: this.title
                },
                subtitle: {
                    text: this.subtitle
                },

                accessibility: {
                    announceNewData: {
                        enabled: true
                    },
                    point: {
                        valueSuffix: '%'
                    }
                },

                plotOptions: {
                    pie: {
                        dataLabels: {
                            distance: '-25%',
                            enabled: true,
                            format: '<span class="donut-chart-label">{point.name}</span>',
                            className: 'donut-chart-label',
                            shadow: false,
                            useHTML: true
                        },
                        startAngle: -90,
                        endAngle: 90,
                        center: ['50%', '75%'],
                        size: '150%'
                    }
                },

                tooltip: {
                    formatter: function(point_label, options) {
                        var p = g.formatPercentage(this.y / this.total);
                        return g.format == "integer" ? this.key + ' : ' + this.y + " (" + p + ")" : this.key + ' : ' + g.formatCurrency(this.y) + " (" + p + ")"
                    }
                    //headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                    //pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
                },

                series: [{
                    name: "Carriers",
                    colorByPoint: true,
                    data: data,
                    innerSize: '50%',
                }],

            }
        }
    },
    mounted: function() {},
    computed: {
        hasNegativeNumbers: function() {
            var r = false
            var g = this
            if (!g.data) {
                return false;
            }
            Object.keys(g.data).forEach(function(k) {
                if (g.data[k] < 0) {
                    r = true;
                }
            })
            return r;
        }
    },
    methods: {
        updateChartData: function(data) {
            this.chartOptions.series[0].data = dataTransformer.pieChart(this.data);
        }
    },
    watch: {
        'data': function(newV) {
            this.updateChartData(newV)
        }
    },
    components: {
        highcharts: Chart
    }
}

</script>
<style scoped>
</style>
