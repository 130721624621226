<template>
    <div>
        <q-score-card key="QScoreCardGmrLeadsRequested" ref="card" title="Standing A Lead Requests" api-endpoint="leads_requested/a" drill-downLabel="Agent Breakdown" drill-down-route="Drilldown_LeadsRequested" card-style="leads" :agent-code="agentCode" :filters="filters" v-on:most_current="updateMostCurrent">
            Note: Tracking GMR orders for A Leads started in June 2021
        </q-score-card>
    </div>
</template>
<script>
import QScoreCard from "./QScoreCard.vue";

export default {
    name: "QScoreCardGmrLeadsRequested",
    props: ["agentCode", "filters"],
    data: function() { // data internal to component (not available outside)
        return {

        }
    },
    mounted: function() {

    },
    computed: {

    },
    methods: {
        updateMostCurrent: function(most_current) {
            this.$emit('most_current', most_current);
        }
    },
    watch: {

    },
    components: {
        QScoreCard,
    }
}

</script>
<style scoped>
</style>
